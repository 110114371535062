import '../App.css';
import React, {useState, useContext } from 'react';
import { NavLink } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../TabContextProvider';

function OpenHeader() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const token = localStorage.getItem('token');
    const { User } = useContext(UserContext);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const navigate = useNavigate();
    const logPage = () => {
        navigate('/login');
    }
    const SignPage = () => {
        navigate('/signup');
    }
    const layoutPage = () => {
        if (User?.is_superuser) {
            navigate('/all-user');
        } else if (!User?.is_superuser) {
            navigate(`/dashboard`);
        }
    }

    return (

        <>
            {/* Header */}
            <div className="row maindash-nav">
                <div className='col-sm-6 col-md-4 col-lg-3 col-xl-3'>
                    <div className=''>
                        <img src="/assets/Layer_1.png" className="logo" alt="logo" onClick={() => navigate(`/`)} style={{ width: '60%', cursor: "pointer" }} />
                    </div>
                </div>
                <div className='col-sm-0 col-md-6 col-lg-6 col-xl-6'>
                    <div className='dash-nav'>
                        <ul style={{ margin: '0px' }}>
                            <li>
                                <NavLink className="nav-link " to="/">Home</NavLink>
                            </li>
                            <li>
                                <a href='/#features' className="nav-link ">Features </a>
                            </li>
                            <li>
                                <a href='/#price' className="nav-link ">Price </a>
                            </li>
                            <li>
                                <NavLink to='/Contact-Us' className="nav-link ">Contact </NavLink>
                            </li>

                        </ul>
                    </div>
                </div>
                <div className='col-sm-6 col-md-2 col-lg-3 col-xl-3 text-center'>
                    <div className='dashlogin'>
                        {!User.id || !token ? (
                            <>
                                <button type="button" onClick={logPage} style={{ border: "none" }} className="btn btn-outline-primary me-2">Sign In</button>
                                <button type="button" onClick={SignPage} className="btn btn-primary">Try for free<i className="fa-solid fa-arrow-right-long ms-2"></i></button>
                            </>
                        ) : (
                            <button type="button" onClick={layoutPage} className="btn btn-primary">Dashboard</button>
                        )}
                    </div>
                </div>
            </div>
            <div className="row maindashmob-nav">
                <div className='col-6'>
                    <div>
                        <img 
                            src="/assets/Layer_1.png" 
                            className="logo" 
                            alt="logo" 
                            onClick={() => navigate(`/`)} 
                            style={{ width: '80%', cursor: "pointer" }} 
                        />
                    </div>
                </div>
                <div className='col-6 d-flex justify-content-end align-items-center'>
                    <button 
                        className="btn btn-primary" 
                        onClick={() => setSidebarOpen(true)} 
                    >
                       <i class="fa-solid fa-bars"></i>
                    </button>
                </div>
            </div>

            {/* Sidebar Component */}
            {sidebarOpen && (
                <div className="sidebar">
                    <button className="close-btn" onClick={() => setSidebarOpen(false)}>Close</button>
                    <ul className="sidebar-menu">
                        <li><NavLink className="nav-link " to="/">Home</NavLink></li>
                        <li> <a href='/#features' className="nav-link ">Features </a></li>
                        <li><a href='/#price' className="nav-link ">Price </a></li>
                        <li><NavLink to='/Contact-Us' className="nav-link ">Contact </NavLink></li>
                        <li>{!User.id || !token ? (
                            <>
                                <button type="button" onClick={logPage} style={{ border: "none" }} className="btn btn-primary w-100 mb-2">Sign In</button>
                                <button type="button" onClick={SignPage} className="btn btn-primary w-100">Try for free<i className="fa-solid fa-arrow-right-long ms-2"></i></button>
                            </>
                        ) : (
                            <button type="button" onClick={layoutPage} className="btn btn-primary">Dashboard</button>
                        )}</li>
                        {/* Add more menu items as needed */}
                    </ul>
                </div>
            )}
            {/* <div className='dash-mob'>
                <div>
                    {Object.keys(User).length == 0 ? (
                        <>
                            <img src="/assets/logo4.png" className="logo dash-logo" alt="logo" />
                            <div>
                                <button type="button" onClick={logPage} style={{ border: "none" }} className="btn btn-outline-primary me-2">Login</button>
                                <button type="button" onClick={layoutPage} className="btn btn-primary">Sign Up</button>
                            </div>
                        </>
                    ) : (
                        <button type="button" onClick={SignPage} className="btn btn-primary">Dashboard</button>
                    )}
                </div>
            </div> */}
            {/* Header */}
        </>
    );
}

export default OpenHeader;