import { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import UserApiClient from "../ApiClients";
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import Swal from "sweetalert2";

const GoogleCallBack = () => {
    const navigate = useNavigate();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();
    const error = query.get('error');
    if (error) {
        Swal.fire(error, '', 'error', 3000);
    }
    const email = query.get('email');
    const token = query.get('token');
    const userId = query.get('userId');

    // Your API call to fetch user data
    const fetchData = async () => {
        await UserApiClient.get(`api/user/${userId}/`, {}, true).then(async res => {
            if (!res.success) {
                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                setTimeout(()=>{window.location.href = '/';}, 2000);
            } else {
                window.location.href = '/dashboard'
            }
        })
    }

    useEffect(() => {
        if (!error) {
            localStorage.setItem('token', token);
            localStorage.setItem('userId', userId);
            fetchData();
        }
    }, [error, email, token, userId])

    return (
        <div className="vh-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: '#f0f4f9' }}>
            <Row className="w-100">
                <Col md={8} className="mx-auto">
                    <Card className="p-5 shadow-sm" style={{ borderRadius: '28px' }}>
                        <Card.Body>
                            <Card.Title className="text-center mb-4">
                                <h4>Sign in with Google</h4>
                            </Card.Title>

                            <Form className="text-center">
                                <h5 className="mb-3">Please wait...</h5>

                                <div className="mb-3">
                                    {!error ? (
                                        <Button variant="outline-secondary" className="w-100 d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <div className="me-2">
                                                    <img
                                                        src="/assets/Logo2.png"
                                                        alt="Nimibot AI"
                                                        className="rounded-circle text-muted"
                                                        style={{ width: "20px" }}
                                                    />
                                                </div>
                                                <span className="text-muted">Nimibot AI</span>
                                            </div>
                                            {email && <span className="text-muted">to continue with: {email}</span>}
                                        </Button>
                                    ) : (
                                        <>
                                            {error && <p>Error: {error}</p>}
                                        </>
                                    )}
                                </div>

                                <div className="mb-3">
                                    <div className="w-100 d-flex align-items-center justify-content-center">
                                        <Button variant="btn btn-primary" onClick={() => { navigate('/') }}>
                                            Home
                                        </Button>
                                    </div>
                                </div>

                                <div className="text-muted small">
                                    Before using this app, you can review Nimibot AI's <NavLink href="/privacy-policy">privacy policy</NavLink> and <NavLink href="/terms-of-service">Terms of Service</NavLink>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>

                    <div className="text-center mt-3 small">
                        <NavLink href="/">Help</NavLink> | <NavLink href="/privacy-policy">Privacy</NavLink> | <NavLink href="/terms-of-service">Terms</NavLink>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default GoogleCallBack