import React from 'react';
import '../../Admin/style.css';
import { NavLink } from "react-router-dom";

function Navbar() {
    return (
        <>
            <div className='navmain'>
                <ul>
                    <li>
                        <div style={{ display: "flex", alignItems: "center" }}><i className="fa fa-home" aria-hidden="true"></i>
                            <NavLink className="nav-link px-4 py-3" to="/admin-dashboard">Dashboard</NavLink>
                        </div>
                    </li>
                </ul>
                <div className="mx-4 my-4" style={{ color: "gray" }}>
                    <h6>Others</h6>
                </div>

                <ul>
                    <li>
                        <div style={{ display: "flex", alignItems: "center" }}><i className="fa-regular fa-user"></i>
                            <NavLink className="nav-link px-4 py-3" to="/all-user">Users</NavLink>
                        </div>
                    </li>

                    <li>
                        <div style={{ display: "flex", alignItems: "center" }}><i className="fa-regular fa-file-lines"></i>
                            <NavLink className="nav-link px-4 py-3" to="/invoice">Invoice</NavLink>
                        </div>
                    </li>

                    <li>
                        <div style={{ display: "flex", alignItems: "center" }}><i className="fa-solid fa-clipboard-list"></i>
                            <NavLink className="nav-link px-4 py-3" to="/news-letter">News Letter</NavLink>
                        </div>
                    </li>

                    <li>
                        <div style={{ display: "flex", alignItems: "center" }}><i className="fa-solid fa-gear"></i>
                            <NavLink className="nav-link px-4 py-3" to="/admin-Setting">Settings</NavLink>
                        </div>
                    </li>

                    <li>
                        <div style={{ display: "flex", alignItems: "center" }}><i className="fa-solid fa-bars-progress"></i>
                            <NavLink className="nav-link px-4 py-3" to="/Seo-Setting">SEO setting</NavLink>
                        </div>
                    </li>

                    {/* <li className="nav-li mt-2">
                    <i className="fa-regular fa-hard-drive"></i>
                    <a className="dropdown-toggle text-decoration-none px-4 py-2" href="#settingMenuone" data-bs-toggle="collapse" aria-expanded="false" style={{ color: "#4a545d" }}>
                        <span className="menu-label">Blogs</span>
                    </a>
                    <ul className="collapse list-unstyled ms-5" id="settingMenuone">
                        <li className='my-2'><a className="text-decoration-none submenus-style" style={{ color: "#4a545d" }} href="/blogs">Blog List</a></li>
                        <li className='my-2'><a className="text-decoration-none submenus-style" style={{ color: "#4a545d" }} href="/blog-category">Category</a></li>
                    </ul>
                </li> */}
                    {/* <li className="nav-li ">
                <i className="fa-solid fa-gear"></i>
                    <a className="dropdown-toggle text-decoration-none px-4 py-2" href="#settingMenutwo" data-bs-toggle="collapse" aria-expanded="false" style={{ color: "#4a545d" }}>
                        <span className="menu-label">Settings</span>
                    </a>
                    <ul className="collapse list-unstyled ms-5" id="settingMenutwo">
                        <li className='my-2'><a className="text-decoration-none submenus-style" style={{ color: "#4a545d" }} href="#">Main setting</a></li>
                        <li className='my-2'><a className="text-decoration-none submenus-style" style={{ color: "#4a545d" }} href="#">SEO setting</a></li>
                    </ul>
                </li> */}

                    {/* <li className="nav-li ">
                    <i className="fa-solid fa-wallet"></i>
                    <a className="dropdown-toggle text-decoration-none px-4 py-2" href="#settingMenufor" data-bs-toggle="collapse" aria-expanded="false" style={{ color: "#4a545d" }}>
                        <span className="menu-label">Users</span>
                    </a>
                    <ul className="collapse list-unstyled ms-5" id="settingMenufor">
                        <li className='my-2'><a className="text-decoration-none submenus-style" style={{ color: "#4a545d" }} href="/all-user">All User</a></li>
                        <li className='my-2'><a className="text-decoration-none submenus-style" style={{ color: "#4a545d" }} href="/active-user">Active User</a></li>
                        <li className='my-2'><a className="text-decoration-none submenus-style " style={{ color: "#4a545d" }} href="/inactive-user">Inactive User</a></li>
                    </ul>
                </li> */}


                    {/* <li className="nav-li">
                    <i className="fa-solid fa-gear"></i>
                    <a className="dropdown-toggle text-decoration-none px-4 py-2" href="#settingMenuthre" data-bs-toggle="collapse" aria-expanded="false" style={{ color: "#4a545d" }}>
                        <span className="menu-label">Help Desk</span>
                    </a>
                    <ul className="collapse list-unstyled ms-5" id="settingMenuthre">
                        <li className='my-2'><a className="text-decoration-none submenus-style" style={{ color: "#4a545d" }} href="#">Active User</a></li>
                        <li className='my-2'><a className="text-decoration-none submenus-style" style={{ color: "#4a545d" }} href="#">Inactive User</a></li>
                    </ul>
                </li> */}

                    {/* <li>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <i className="fa-solid fa-right-from-bracket"></i>
                            <NavLink className="nav-link px-4 py-3" to="/admin-login">Sign Out</NavLink>
                        </div>
                    </li> */}

                </ul>
                {/* <div className='adupgradenow'>
                    <h6 style={{ lineHeight: "inherit" }}>Get X$ Amount off if you Subscribe Yearly!</h6>
                    <button type="button" className="btn btn-light">Upgrade Now!</button>
                </div> */}
            </div>
            <div className='copyright'>
                <h6>NimiBot</h6>
                <h6 className="form-text text-muted"><i className="fa-regular fa-copyright"></i>2024 All Rights Reserved</h6>
                <h6 className="form-text text-muted">Made with <i className="fa-solid fa-heart" style={{ color: "#ff0008" }}></i> by NimiBot</h6>
            </div>
        </>
    );
}

export default Navbar;