import '../../../Admin/style.css';
import React, { useState, useEffect } from 'react';
import Navbar from '../../Sidebars/Navbar.jsx';
import Header from '../../Sidebars/header.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation } from 'react-router-dom';
import UserApiClient from '../../../ApiClients/index.jsx';
import Swal from 'sweetalert2';

function CustomTabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Userprofiles() {

    const { id } = useParams();
    const navigate = useNavigate();
    let isFetching = false;
    let isFetchingTeam = false;
    if (!id || id == "undefined") navigate('/admin-dashboard');

    const [userData, setUserData] = useState({});
    const [Teams, setTeams] = useState([]);
    const [value, setValue] = useState(0);
    const location = useLocation();
    const [invoices, setInvoices] = useState([]);
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [isMobCheck, setIsMobCheck] = useState();

    const handleResize = () => {
        setIsNavbarVisible(window.innerWidth >= 768);
    };

    useEffect(() => {
        setIsMobCheck(window.innerWidth >= 768);
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Your API call to fetch data
    const fetchData = async () => {
        isFetching = true
        try {
            const res = await UserApiClient.get(`api/user/details/${id}/`, {}, true);
            if (res.success) {
                setUserData(res.data);
            }
            isFetching = false
        } catch (error) {
            console.log("Error fetching user data:", error);
        }
    };

    // Your API call to fetch data
    const fetchTeamData = async () => {
        isFetchingTeam = true
        try {
            const res = await UserApiClient.get(`api/admin/team/list/${userData.id}/`, {}, true);
            if (res.success) {
                setTeams(res.data);
            }
            isFetchingTeam = false
        } catch (error) {
            console.log("Error fetching user data:", error);
        }
    };

    useEffect(() => {
        if (id && !isFetching) {
            fetchData()
        }
    }, []);

    useEffect(() => {
        if (userData.invoices) {
            setInvoices(userData.invoices)
        }
        if (userData.id && !isFetchingTeam) {
            fetchTeamData()
        }
    }, [userData]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tabIndex = parseInt(params.get('tab'), 10);
        if (!isNaN(tabIndex) && tabIndex >= 0 && tabIndex <= 2) {
            setValue(tabIndex);
        }
    }, [location.search]);

    async function handleEnterpriceSubscription(e, team_id, plan_type) {
        await Swal.fire({
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `Yes, Confirm!`
        }).then(async (result) => {
            if (result.isConfirmed) {
                await UserApiClient.post(`api/admin/plan/enterprising/${team_id}/`, { 'state': plan_type != 'enterprise_subscription' }, true).then(async res => {
                    if (res.success) {
                        await fetchData();
                        Swal.fire(res.data.message, '', 'success', 3000);
                    }
                })
            }
        });
    }
    console.log(Teams, "------------");
    

    // async function handlePauseResumeSubscription() {
    //     await Swal.fire({
    //         title: "Are you sure?",
    //         text: `You won't be able to revert this!`,
    //         icon: "question",
    //         showCancelButton: true,
    //         confirmButtonColor: "#3085d6",
    //         cancelButtonColor: "#d33",
    //         confirmButtonText: `Yes, ${userData.plan?.is_paused ? 'Resume' : 'Pause'}!`
    //     }).then(async (result) => {
    //         if (result.isConfirmed) {
    //             await UserApiClient.post(`api/admin/plan/${userData.plan?.is_paused ? 'resume' : 'pause'}/${userData.id}/`, {}, true).then(async res => {
    //                 if (res.success) {
    //                     await fetchData();
    //                     Swal.fire(res.data.message, '', 'success', 3000);
    //                 }
    //             })
    //         }
    //     });
    // }


    async function handlePauseResumeSubscription(e, planPaused, teamId) {
        await Swal.fire({
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `Yes, ${planPaused ? 'Resume' : 'Pause'}!`
        }).then(async (result) => {
            if (result.isConfirmed) {
                await UserApiClient.post(`api/recurring/${planPaused ? 'resume' : 'pause'}/${teamId}/`, {}, true).then(async res => {
                    if (res.success) {
                        await fetchTeamData();
                        Swal.fire(res.data.message, '', 'success', 3000);
                    }
                })
            } else {
                e.target.checked = !e.target.checked;
            }
        });
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        // Update URL to reflect the current tab
        const params = new URLSearchParams(window.location.search);
        params.set('tab', newValue);
        window.history.replaceState(null, '', `?${params.toString()}`);
    };

    const toggleNavbar = () => {
        setIsNavbarVisible(prevState => !prevState);
    };

    return (
        <>
            <div className="container-fluid p-0">
                <Header toggleNavbar={toggleNavbar} isNavbarVisible={isNavbarVisible} />
                <div className="row">
                    <div className={`sizefix col-md-0 col-lg-3 col-xl-2 p-0 ${isNavbarVisible ? '' : 'd-none'}`}
                        style={{ borderRight: "1px solid #ebebeb" }}>
                        <Navbar />
                    </div>
                    <div className={`${isMobCheck ? (isNavbarVisible ? 'col-10 p-0' : 'col-12 p-0') : (isNavbarVisible ? 'd-none' : 'col-12 p-0')}`}>
                        <div className="main-sec m-0">
                            <div className='serchblog p-4' >
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <h5 className='m-0' style={{ width: "100%" }}>User Profile</h5>
                                </div>
                                <button onClick={() => { navigate('/all-user') }} className='btn btn-primary px-4 py-2'>Users List</button>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='userprofile'>
                                        {/* <div className='bloglist' style={{justifyContent:'end'}}>
                                        <button><span>Edit<i className="fa-solid fa-pen-to-square ml-2"></i></span></button>
                                    </div> */}
                                        <div style={{ display: 'flex' }}>
                                            <img src={userData.profile_pic || '/assets/profile-user.png'} alt="user-logo" style={{ width: "10%", marginRight: "25px", borderRadius: "50%", border: "1px solid #fff", background: "#fff" }}></img>
                                            <div>
                                                <h1 className='text-capitalize mb-2'>{userData.first_name} {userData.last_name}</h1>
                                                <p>{userData.email}</p>
                                                <p>Login via {userData.by_google ? "Google" : 'Creds'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-12 col-lg-10 col-xl-6 mainswitch'>
                                            <Box sx={{ border: '1px ridge', backgroundColor: '#ffffff', padding: '8px', borderRadius: '50px' }}>
                                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                                    <Tab label="About" {...a11yProps(0)} />
                                                    <Tab label="Teams" {...a11yProps(1)} />
                                                    <Tab label="Billing" {...a11yProps(2)} />
                                                </Tabs>
                                            </Box>
                                        </div>
                                    </div>
                                    <CustomTabPanel value={value} index={0}>
                                        <div className='profilecontainer p-4'>
                                            <h4>Personal Details</h4>
                                            <div className='d-flex'>
                                                <div className='py-2 px-4' style={{ color: "grey" }}>
                                                    <p><strong>Name: </strong></p>
                                                    <p><strong>Joined Date: </strong></p>
                                                    <p><strong>Email ID: </strong></p>
                                                    <p><strong>Mobile: </strong></p>
                                                    <p><strong>Address: </strong></p>
                                                    <p><strong>State: </strong></p>
                                                    <p><strong>Country: </strong></p>
                                                </div>
                                                <div className='py-2 px-4'>
                                                    <p>{userData.first_name} {userData.last_name}</p>
                                                    <p>{userData.date_joined}</p>
                                                    <p>{userData.email}</p>
                                                    <p>{userData.phone_number}</p>
                                                    <p>{userData.address}</p>
                                                    <p>{userData.state}</p>
                                                    <p>{userData.country}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1}>
                                        <div className="table-responsive">
                                            <table className="table category-tab text-start">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Sr.</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">URL/Endpoint</th>
                                                        <th scope="col">Created on</th>
                                                        <th scope="col">Subscription</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Teams.map((item, index) => (
                                                        <tr key={item.id}>
                                                            <td>#{index + 1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.slug}</td>
                                                            <td>{item.created_at}</td>
                                                            <td className='text-capitalize'>
                                                                {item.plan_type == 'free' || item.plan_type == 'enterprise_subscription' ?
                                                                    item.plan_type?.replaceAll('_', ' ') : (
                                                                        <label className="switch">
                                                                            <input type="checkbox" defaultChecked={!item.plan_paused} onChange={(e) => handlePauseResumeSubscription(e, item.plan_paused, item.id)} />
                                                                            <span className="slider round"></span>
                                                                        </label>
                                                                    )}
                                                            </td>
                                                            <td>
                                                                <button class="btn btn-primary px-4 py-2" onClick={(e) => handleEnterpriceSubscription(e, item.id, item.plan_type)}>{item.plan_type != 'enterprise_subscription' ? "Enterprise": "Free"}</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={2}>
                                        <div className="table-responsive">
                                            <table className="table category-tab text-start">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">Plan</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Created on</th>
                                                        <th scope="col">Expiry Date</th>
                                                        <th scope="col">Status</th>
                                                        {/* <th scope="col">Refund</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {invoices.map(item => (
                                                        <tr key={item.id}>
                                                            <td>#{item.id}</td>
                                                            <td>{item.subscription_type}</td>
                                                            <td>${item.amount}</td>
                                                            <td>{item.created_at}</td>
                                                            <td>{item.subscription_duration}</td>
                                                            <td>
                                                                <span style={{
                                                                    backgroundColor: item.invoice_status == "success" ? '#28C76F' : 'brown',
                                                                    borderRadius: '20px',
                                                                    padding: '3px 15px',
                                                                    color: '#fff',
                                                                    textTransform: 'capitalize'
                                                                }}>
                                                                    {item.invoice_status}
                                                                </span>
                                                            </td>
                                                            {/* <td>
                                                                <button className='icn-btn' onClick={handleRefund}>
                                                                    <i className="fas fa-undo-alt mx-1"></i>
                                                                </button>
                                                            </td> */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CustomTabPanel>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Userprofiles;
