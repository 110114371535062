import '../App.css';
import React, { useContext } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../TabContextProvider';
import Swal from 'sweetalert2';
import UserApiClient from '../ApiClients';


function Header() {
  const params = useParams();
  const { team_url, chatbot_name } = params;

  const { User, setUser, TeamList, setTeamList, Team, setTeam } = useContext(UserContext);
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const handleTeamChange = (e, value) => {
    if (value) {
      const team = TeamList.find(item => item.slug === value);
      setTeam(team);
    }
  }

  const handleCreateTeam = (e) => {
    Swal.fire({
      title: "Add New Team",
      html: `
        <input id="name" type="text" class="form-control mx-auto my-2 w-100" placeholder="Enter Team Name" maxlength="250">
        <input id="slug" type="text" class="form-control mx-auto mb-2 w-100" placeholder="Enter Team url" maxlength="250">
          `,
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Add Team",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const name = Swal.getPopup().querySelector('#name').value;
        const slug = Swal.getPopup().querySelector('#slug').value;
        const pattern = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
        // Validate the fields
        if (!name) {
          Swal.showValidationMessage(`Name fields is required`);
          return;
        }
        if (!slug) {
          Swal.showValidationMessage(`Url fields is required`);
          return;
        }
        if (!pattern.test(slug)) {
          Swal.showValidationMessage(`Please enter a valid url endpoints`);
          return;
        }

        // Create FormData object to handle file uploads
        const formData = new FormData();
        formData.append('name', name);
        formData.append('slug', slug);
        formData.append('created_by', User.id);

        const res = await UserApiClient.post(`api/team/get_or_create/${User.id}/`, formData, true, undefined, false);
        if (res.success) {
          const data = await res.data;
          return { data };
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed && result.value.data) {
        Swal.fire({
          title: result.value.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2000
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    });
  }

  const handleLogout = function (e) {
    Swal.fire({
      title: "Logout Successfull.",
      icon: 'success',
      showConfirmButton: false,
      timer: 3000
    })
    setUser({})
    setTeam({})
    setTeamList([])
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    navigate('/')
  };
  
  return (
    <>
      <div className="header-sec">
        {/* <div className='headerlogo-sec text-center' onClick={() => { navigate('/') }}>
          <img src="/assets/Layer_1.png" className="logo" alt="logo" style={{ width: '80%' }} />
        </div> */}
        <div className="input-group">
          <div className='headname'>
            <h4>{chatbot_name ? chatbot_name : pathname.includes('dashboard') ? "Dashboard" : pathname.includes('layout') ? "Chatbot" : pathname.includes('help') ? "Help" : pathname.includes('settings') ? 'Account Settings' : ''}</h4>
            <div className="dropdown" style={{ display: "flex" }}>
              <button className="btn dropdown-toggle text-start p-0" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style={{ minWidth: "160px" }}>
                {`${Team.name?.length > 16 ? Team.name?.slice(0, 16) + "..." : Team.name || "Your Team"}`}
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {/* onChange={handleTeamChange} */}
                {TeamList.map((item, index) => (
                  // <option key={index} value={item.slug}>{item.name}</option>
                  <li key={index}>
                    <div className={`dropdown-item ${team_url == item.slug ? 'active' : ''}`} onClick={(e) => handleTeamChange(e, item.slug)} style={{ minWidth: "160px", cursor: 'pointer' }}>{item.name}</div>
                  </li>
                ))}
                <li><div className="dropdown-item" onClick={handleCreateTeam} style={{ color: '#3fa4e8', cursor: 'pointer' }}><i className="fa-solid fa-plus"></i> Create New Team</div></li>
              </ul>
            </div>          </div>
          {/* <form className='searchbar'>
            <input type="text" placeholder="Search.." name="search" />
            <button type="submit"><i className="fa fa-search"></i></button>
          </form> */}
        </div>

        <div className='mobdrop'>
          <div className="dropdown userpro" style={{ display: "flex" }}>
            <img src={User?.profile_pic || "/assets/profile-user.png"} alt="user-logo" style={{ width: "35px", height:"35px", borderRadius: "50%" }}></img>
            <button className="btn dropdown-toggle pe-0 text-capitalize" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              {`${User.first_name} ${User.last_name}`}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li><NavLink className="dropdown-item" to={`/dashboard/${Team?.slug || ''}`}>Dashboard</NavLink></li>
              <li><NavLink className="dropdown-item" to="/settings">Account Settings</NavLink></li>
              <li><div className="dropdown-item cursor-pointer" href="#" onClick={handleLogout}>Log out</div></li>
            </ul>
          </div>
        </div>

        <div className='deskdrop w-100'>
          <div className='d-flex justify-content-between'>
        <div className="dropdown" style={{ display: "flex" }}>
        <img src="/assets/Logo2.png" alt="logo" style={{ width: "40px", borderRadius: "50%", marginRight: "8px" }}></img>
              <button className="btn dropdown-toggle text-start p-0" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" >
                {`${Team.name?.length > 16 ? Team.name?.slice(0, 16) + "..." : Team.name || "Your Team"}`}
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {/* onChange={handleTeamChange} */}
                {TeamList.map((item, index) => (
                  // <option key={index} value={item.slug}>{item.name}</option>
                  <li key={index}>
                    <div className={`dropdown-item cursor-pointer ${team_url == item.slug ? 'active' : ''}`} onClick={(e) => handleTeamChange(e, item.slug)}>{item.name}</div>
                  </li>
                ))}
                <li><div className="dropdown-item cursor-pointer" onClick={handleCreateTeam} style={{ color: '#3fa4e8' }}><i className="fa-solid fa-plus"></i> Create New Team</div></li>
              </ul>
            </div> 
          <div className="dropdown" style={{ display: "flex" }}>
            <img src={User?.profile_pic || "/assets/profile-user.png"} alt="user-logo" style={{ width: "40px", borderRadius: "50%" }}></img>
            <button className="btn dropdown-toggle text-capitalize" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              {`${User.first_name} ${User.last_name}`}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li><NavLink className="dropdown-item" to={`/dashboard/${Team?.slug || ''}`}>Dashboard</NavLink></li>
              <li><NavLink className="dropdown-item" to="/">Help</NavLink></li>
              <li><NavLink className="dropdown-item" to="/settings">Account Settings</NavLink></li>
              <li><div className="dropdown-item cursor-pointer" href="#" onClick={handleLogout}>Log out</div></li>
            </ul>
          </div>
        </div>
        </div>

      </div>
    </>
  );
}

export default Header;
