import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import OpenHeader from '../Components/OpenHeader';
import UserApiClient from '../ApiClients';
import Swal from 'sweetalert2';
import { UserContext } from '../TabContextProvider';

const Contact = () => {

    const [data, setData] = useState({});
    const {User} = useContext(UserContext);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleFromSubmit = async (e) => {
        e.preventDefault();
        if (User?.id) {
            setData({...data, ['user']: User.id,});
        }
        const res = await UserApiClient.post(`bot/contact-us/`, { ...data }, false);
        if (res.success && res.data?.id) {
            Swal.fire("Request submitted successfully.", '', 'success', 3000);
            e.target.reset();
        }
    }
    useEffect(()=>{
        if (User?.id) {
            setData({...data, ['user']: User.id});
        }
    }, [User])

    return (
        <>
            {/* Header */}
            <OpenHeader />
            {/* Header */}
            <div className="contact-us">
                <div className='dash-contact' style={{ background: " #fff" }}>
                    <h1 style={{ color: " #a1a1aa" }} >Contact Us</h1>
                    <p>NimiBot provides a simple and secure way to create custom AI Chatbot.</p>
                    <form onSubmit={handleFromSubmit}>
                        <div className="row">
                            <div className="col">
                                <input type="text"
                                    name='name'
                                    className="form-control"
                                    placeholder="Full Name"
                                    onChange={handleChange}
                                    maxLength={250}
                                    required
                                />
                            </div>
                            <div className="col">
                                <input type="email"
                                    name='email'
                                    className="form-control"
                                    placeholder="Your email"
                                    onChange={handleChange}
                                    maxLength={250}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <textarea className="form-control"
                                placeholder="Message"
                                rows="3"
                                id="comment"
                                name='description'
                                onChange={handleChange}
                                required
                                ></textarea>
                        </div>
                        <button type="submit" className="btn btn-primary px-5 mb-3">Send</button>
                    </form>
                    <p>contact@nimibot.com</p>
                </div>
            </div>
        </>
    );
};

export default Contact;