import { NavLink, useNavigate } from 'react-router-dom';
import '../App.css';
import React, { useContext, useEffect, useState } from 'react';
import UserApiClient from '../ApiClients';
import Swal from 'sweetalert2';
import GoogleSignIn from './LoginWithGoogle';
import validatePassword from '../Configs/passValidation';
import { UserContext } from '../TabContextProvider';

function SignUp() {
    const token = localStorage.getItem('token');
    const { User } = useContext(UserContext);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({})
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prev) => !prev);
    };
    const [error, setError] = useState('');


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name == "password") {
            const validationError = validatePassword(value);
            setError(validationError);
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (error.length > 0) return;

        await UserApiClient.post('api/register/', formData).then(async res => {
            let resp = await res.success;
            let data = await res.data
            if (resp && data) {
                Swal.fire({
                    title: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                })
                navigate('/login');
            } else {
                console.log(data.detail)
            }
        })
    };

    useEffect(() => {
        if (User.id && !!token) {
            navigate('/dashboard')
          }
    }, [User])

    return (
        <>
            <div className="row user-login">
                <div className='col-sm-0 col-md-0 col-lg-3 col-xl-3 logo-set'>
                    <button type="button" className="btn backarrow mx-5 my-3" onClick={() => navigate('/')} style={{ fontSize: '20px', color: "#47a4e5", cursor: "pointer" }}>
                        <i className="fa-solid fa-circle-arrow-left me-2" ></i>Back</button>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 login-form'>
                    <div className='login-img'><img src="/assets/Layer_1.png" className="logo" onClick={() => navigate('/')} alt="logo" style={{ width: "30%", cursor: "pointer" }} /></div>
                    <div className='signin-set'>
                        <div className='signin'>
                            <div className='text-center mb-5'><h3>Get started for free</h3></div>
                            {/* <h3>Sign Up</h3> */}
                            <form onSubmit={handleFormSubmit}>
                                <div className="row">
                                    <div className="col">
                                        <input type="text" name='first_name' onChange={handleChange} className="form-control m-0" placeholder="First name" required />
                                    </div>
                                    <div className="col">
                                        <input type="text" name='last_name' onChange={handleChange} className="form-control m-0" placeholder="Last name" required />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="email" name='email' className="form-control" onChange={handleChange} placeholder="Enter email" required />
                                </div>
                                <div className="form-group ">
                                    <div className='passicon'>
                                        <input type={isPasswordVisible ? 'text' : 'password'} className="form-control" name="password" onChange={handleChange} placeholder="Password" required />
                                        <i className={`fa-regular fa-eye-slash ${isPasswordVisible ? 'd-none' : ''}`} onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} ></i>
                                        <i className={`fa-solid fa-eye ${isPasswordVisible ? '' : 'd-none'}`} onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} ></i>
                                    </div>
                                </div>
                                {error && <div style={{ color: 'red' }}>{error}</div>}
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id='termOfUseCheckbox' required />
                                    <label className="form-check-label" htmlFor="termOfUseCheckbox">
                                       <small>By Clicking, I agree that I have read and accepted the <NavLink href='/privacy-policy'>Terms of use</NavLink> and <NavLink href='/terms-of-service'>Privacy Policy</NavLink>
                                       </small> </label>
                                </div>
                                <button type="submit" className="btn btn-primary signin-btn mt-4">Sign Up</button>
                                <div className='orline'><hr style={{ width: '20%' }} ></hr><p className='m-0'>OR CONTINUE WITH</p><hr style={{ width: '20%' }}></hr></div>
                                <div className=''>
                                    <GoogleSignIn />
                                    {/* <small id="emailHelp" className="form-text text-muted">By continuing, you agree to our  <NavLink to='/privacy-policy'> Privacy Policy</NavLink> and <NavLink to='/terms-of-service'>Terms of service</NavLink></small> */}
                                </div>
                            </form>
                        </div>
                        <div className='mt-4' style={{ textAlign: 'center' }}><small>Already have an account? <NavLink to='/login'><b>Sign In</b></NavLink></small></div>
                    </div>
                </div>
                <div className='col-sm-0 col-md-0 col-lg-3 col-xl-3'>
                </div>
            </div>
        </>
    );
}

export default SignUp;
