import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import UserApiClient from '../../ApiClients';
import Swal from 'sweetalert2';
import { AppDomain } from '../../Configs/enviroment';

// const switchStyles = {
//     position: 'relative',
//     display: 'inline-block',
//     width: '80px', // Reduced width
//     height: '40px', // Reduced height
//     margin: '20px', // Adjusted margin
// };

// const inputStyles = {
//     opacity: 0,
//     width: 0,
//     height: 0,
// };

// const sliderrStyles = {
//     position: 'absolute',
//     cursor: 'pointer',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: '#ccc',
//     transition: '.4s',
//     borderRadius: '20px', // Reduced border radius
// };

// const sliderrRoundStyles = {
//     position: 'absolute',
//     content: '""',
//     height: '30px', // Reduced height
//     width: '30px', // Reduced width
//     borderRadius: '50%',
//     left: '5px', // Adjusted left position
//     bottom: '5px', // Adjusted bottom position
//     backgroundColor: 'white',
//     transition: '.4s',
// };

// const onTextStyles = {
//     position: 'absolute',
//     width: '50%',
//     textAlign: 'center',
//     lineHeight: '40px', // Adjusted line height
//     color: 'white',
//     fontSize: '14px', // Reduced font size
//     fontWeight: 'bold',
//     transition: '.4s',
//     left: 0,
// };

// const offTextStyles = {
//     position: 'absolute',
//     width: '50%',
//     textAlign: 'center',
//     lineHeight: '40px', // Adjusted line height
//     color: 'white',
//     fontSize: '14px', // Reduced font size
//     fontWeight: 'bold',
//     transition: '.4s',
//     right: 2,
// };

// const switchCheckedStyles = {
//     backgroundColor: '#2196F3',
// };

// const sliderrCheckedStyles = {
//     transform: 'translateX(40px)', // Adjusted based on the new width of the slider
// };


function Embedsite({ data }) {

    const { chatbotData, chatbotAppearance, Team, setChatbotData } = data;

    const [isChecked, setIsChecked] = useState(!!chatbotData?.visibility == 'private');
    const [copyText, setCopyText] = useState('');
    const [copyTexttwo, setCopyTexttwo] = useState('');

    useEffect(() => {
        setCopyText(
            `
<iframe
src="${AppDomain}chatbot-iframe/${chatbotData.chatbot_id}/${Team.id}/"
title="Nimibot"
width="400px"
style="height: 100%; min-height: 600px;"
frameborder="0">
</iframe>`)

        setCopyTexttwo(`
<script>
window.embeddedChatbotConfig = {
chatbotId: "${chatbotData.chatbot_id}",
teamId: "${Team.id}",
domain: "${AppDomain}" 
};
</script>
<script 
id='nimibot-widget'
src="${AppDomain}widget/embed.min.js" 
chatbotId="${chatbotData.chatbot_id}" 
defer async>
</script>
`);
    }, []);

    const Makepublic = async (e) => {

        data = {
            visibility: 'public',
            chatbot_appearance: chatbotAppearance.id
        }

        await UserApiClient.put(`bot/chatbot/update/${chatbotData.id}/`, data, true).then(async res => {
            if (res.success) {
                setChatbotData({ ...chatbotData, ...res.data });
                setIsChecked(false);
                Swal.fire(`Chatbot visibility updated to ${res.data.visibility}.`, '', 'success', 3000)
            }
        })
        setIsChecked(!isChecked);
    };

    const handleCopyText = (event) => {
        setCopyText(event.target.value);
    };

    const copyToClipboard = () => {
        copy(copyText);
        Swal.fire('You have copied successfully.', '', 'success', 2000)
    };
    const handleCopyTexttwo = (event) => {
        setCopyTexttwo(event.target.value);
    };

    const copyToClipboardtwo = () => {
        copy(copyTexttwo);
        Swal.fire('You have copied successfully.', '', 'success', 2000)
    };

    useEffect(()=>{
        if (chatbotData?.visibility) {
            setIsChecked(chatbotData?.visibility == 'public'? true:false);
        }
    }, [chatbotData])



    return (
        <>


            {
                isChecked ?
                    <div className='containers p-0' style={{ minHeight: "10rem" }}>
                        <div className='chat-container m-0'>
                            <h4 className='mx-4 my-2'><strong>Embed Website</strong></h4>
                        </div>
                        <div className='row'>
                            <div className="col-12 px-4">
                                <p className='mt-4 ms-3'>To add the chatbot any where on your website, add this iframe to your html code.</p>
                                <div style={{ padding: '20px', textAlign: 'center' }}>
                                    <textarea
                                        type="text"
                                        value={copyText}
                                        onChange={handleCopyText}
                                        className='form-control mb-4'
                                        rows={6}
                                        style={{ fontFamily: 'monospace', minHeight: "12rem", fontSize: "14px", padding: "5px", color: '#000' }}
                                        disabled
                                        readOnly
                                    />
                                    <button onClick={copyToClipboard} type="button" className="btn btn-primary">Copy Iframe<i className="fa-regular fa-clipboard ms-3"></i></button>
                                </div>
                                <p className='mt-4 ms-3'>To add a chat bubble to the bottom right of your website add this Script tag to your html.</p>
                                <div style={{ padding: '20px', textAlign: 'center' }}>
                                    <textarea
                                        type="text"
                                        value={copyTexttwo}
                                        onChange={handleCopyTexttwo}
                                        className='form-control mb-4'
                                        rows={6}
                                        style={{ fontFamily: 'monospace', minHeight: "18rem", fontSize: "14px", padding: "5px", color: '#000' }}
                                        disabled
                                        readOnly
                                    />
                                    <button onClick={copyToClipboardtwo} type="button" className="btn btn-primary">Copy Script<i className="fa-regular fa-clipboard ms-3"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-sm-12 col-10 p-0">
                        <div className='containers ' style={{ minHeight: "10rem" }}>
                            <div className='chat-container m-0 px-4 pt-1'>
                                <h4 className='my-2'><strong>Make Public</strong></h4>
                            </div>
                            <div className='row px-4 pt-2'>

                                <div className='switch-container ' style={{ display: 'flex', alignItems: 'center' }}>
                                    <h6>Chatbot is private, to share the chatbot change the visibility to public.</h6>
                                    {/* <label className="switch" style={switchStyles}>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleChange}
                                    style={inputStyles}
                                />
                                <span
                                    className="sliderr round"
                                    style={{
                                        ...sliderrStyles,
                                        ...(isChecked ? switchCheckedStyles : {}),
                                    }}
                                >
                                    <span
                                        className="on-text"
                                        style={{
                                            ...onTextStyles,
                                            opacity: isChecked ? 1 : 0,
                                        }}
                                    >
                                        ON
                                    </span>
                                    <span
                                        className="off-text"
                                        style={{
                                            ...offTextStyles,
                                            opacity: isChecked ? 0 : 1,
                                        }}
                                    >
                                        OFF
                                    </span>
                                    <span
                                        className="sliderr-round"
                                        style={{
                                            ...sliderrRoundStyles,
                                            ...(isChecked ? sliderrCheckedStyles : {}),
                                        }}
                                    ></span>
                                </span>
                            </label> */}
                                </div>
                                <div style={{ textAlign: "end" }}><button type="button" onClick={Makepublic} className="btn btn-primary m-3">Make Public</button></div>
                            </div>
                        </div>
                    </div>
            }

        </>
    );
}

export default Embedsite;
