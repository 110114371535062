import '../App.css';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import UserApiClient from '../ApiClients';
// import loader from '../Configs/loader';
import { UserContext } from '../TabContextProvider';
import { BaseApiUrl } from '../Configs/enviroment';
import GoogleSignIn from './LoginWithGoogle';
import validatePassword from '../Configs/passValidation';

function Login() {
  const token = localStorage.getItem('token');
  const { User, setUser } = useContext(UserContext);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({})
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [error, setError] = useState('');


  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name == "password") {
      const validationError = validatePassword(value);
      setError(validationError);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (error.length > 0) return;

    await UserApiClient.post('api/login/', formData).then(async res => {
      let data = await res.data
      if (res.success && data) {
        setUser(data.user);
        localStorage.setItem('token', data.access);
        localStorage.setItem('userId', data.user.id);
        navigate(`/dashboard`);
        // await Swal.fire({
        //   title: "Welcome, Sign In Successfully.",
        //   icon: 'success',
        //   showConfirmButton: false,
        //   timer: 1500
        // })
      } else {
        console.log(data.detail)
      }
    })
  };

  useEffect(() => {
    if (User.id && !!token) {
      navigate('/dashboard')
    }
  }, [User])

  return (
    <>
      <div className="row user-login">
        <div className='col-sm-0 col-md-0 col-lg-3 col-xl-3 logo-set'>
          <button type="button" className="btn backarrow mx-5 my-3" onClick={() => navigate('/')} style={{ fontSize: '20px', color: "#47a4e5", cursor: "pointer" }}>
            <i className="fa-solid fa-circle-arrow-left me-2" ></i>Back</button>
        </div>
        <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 login-form'>
          <div className='login-img'><img src="/assets/Layer_1.png" className="logo" onClick={() => navigate('/')} alt="logo" style={{ width: "30%", cursor: "pointer" }} /></div>
          <div className='signin-set'>
            <div className='signin'>
              <div className='text-center mb-5'><h4>Welcome Back</h4></div>
              {/* <h4>Sign in</h4> */}
              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <input type="email" className="form-control" name='email' onChange={handleChange} placeholder="Enter email" required />
                </div>
                <div className="form-group">
                  <div className='passicon'>
                    <input type={isPasswordVisible ? 'text' : 'password'} className="form-control" name="password" onChange={handleChange} placeholder="Password" required />
                    <i className={`fa-regular fa-eye-slash ${isPasswordVisible ? 'd-none' : ''}`} onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} ></i>
                    <i className={`fa-solid fa-eye ${isPasswordVisible ? '' : 'd-none'}`} onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} ></i>
                  </div>
                </div>
                {error && <div style={{ color: 'red' }}>{error}</div>}
                <div className='forgetpass'><NavLink to='/forget-pass'>Forget Password?</NavLink></div>
                <button type="submit" className="signin-btn btn btn-primary">Sign In</button>
                <div className='orline'><hr style={{ width: '40%' }} ></hr><p className='m-0'>or</p><hr style={{ width: '40%' }}></hr></div>
                <div className=''>
                  <GoogleSignIn />
                  <small id="emailHelp" className="form-text text-muted">By continuing, you agree to our <NavLink to='/privacy-policy'> Privacy Policy</NavLink> and <NavLink to='/terms-of-service'>Terms of service</NavLink></small>
                </div>
              </form>
            </div>
            <div className='mt-4' style={{ textAlign: 'center' }}><small>Don't have an account?
              <NavLink to='/signup'> <b>Sign up</b></NavLink></small></div>
          </div>
        </div>
        <div className='col-sm-0 col-md-0 col-lg-3 col-xl-3'>
        </div>
      </div>
    </>
  );
}

export default Login;
