import '../App.css';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../TabContextProvider';

function Chatbots({ chatbotList, handleChatbotDelete, handleAddNew, isFetchingList }) {

  const { User, Team } = useContext(UserContext);
  const [toggle, setToggleState] = useState({});

  const navigate = useNavigate();

  // const {user}
  const deletebot = (id) => {
    setToggleState(prevState => ({ ...prevState, [id]: true }));
  };

  const cancelDelete = (id) => {
    setToggleState(prevState => ({ ...prevState, [id]: false }));
  };

  return (
    <>
      <div className="main-sec">
        <div className='serchblog' >
          <div style={{ display: "flex", alignItems: "center" }} >
            <h5 className='m-0'><strong>Chatbot List</strong></h5>
            {/* <h5 className='m-0' style={{ width: "50%" }}>ChatBot User List</h5> */}
            {/* <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"></input> */}
          </div>
          {/* <button className='btn btn-primary px-4 py-2'>Create Blog</button> */}
        </div>
        <div className='row mt-5'>
          {isFetchingList && chatbotList.length === 0 ? (
            <div className='col-sm-12 col-md-12 col-lg-4 col-xl-3'>
              <div className="animate-pulse d-flex align-items-start pt-3 adContainer">
                <span className="placeholder rounded-circle bd-h-10 bd-w-10"></span>
                <div className="flex-grow-1 me-2">
                  <span className="placeholder placeholder-xs col-10 py-2"></span>
                  <span className="placeholder placeholder-xs col-12 mt-4"></span>
                  <span className="placeholder placeholder-xs col-4 mt-3"></span>
                  <span className="placeholder placeholder-xs col-12"></span>
                  <span className="placeholder placeholder-xs col-4 mt-3"></span>
                  <span className="placeholder placeholder-xs col-12"></span>
                  <span className="placeholder placeholder-xs col-4 mt-3"></span>
                  <span className="placeholder placeholder-xs col-12"></span>
                </div>
              </div>
            </div>
          ) : ""}
          {chatbotList.map((item, index) => (
            <div className='col-sm-12 col-md-12 col-lg-4 col-xl-3' key={index}>
              <div>
                <div className='adContainer'>
                  <div className='bloglist text-capitalize mb-3'>
                    {toggle[item.id] ?
                      <span></span> :
                      (<>
                        <h5><strong>Sources</strong></h5>
                        <div>
                          <button onClick={() => deletebot(item?.id)}>
                            <i className="fa-solid fa-trash-can mx-2"></i>
                          </button>
                        </div>
                      </>)
                    }
                  </div>
                  {toggle[item.id] ? (
                    <div className='text-center mt-4'>
                      <button type="button" className="btn btn-danger my-3 w-75" onClick={(e) => handleChatbotDelete(e, item?.id)}>Delete Chatbot</button><br />
                      <button type="button" onClick={() => cancelDelete(item?.id)} className="btn btn-secondary mb-3 w-75">Don't Delete</button><br />
                      <p style={{ color: 'red', fontSize: '14px' }}>Deleting a chatbot will be permanent.<br />Chatbots cannot be restored.</p>
                    </div>
                  ) : (
                    <>
                      <h6>Total detected characters</h6>
                      <h6>{item.total_chars || 0}</h6>
                      <h6>Input Links</h6>
                      <h6>{item.website_count || 0}</h6>
                      <h6>Uploaded Documents</h6>
                      <h6>{item.document_count || 0}</h6>
                      <div className='text-center'>
                        <button type="button" className="btn btn-primary mb-2 w-75" onClick={() => navigate(`/layout/${Team.slug}/${item?.chatbot_name}/${item?.id}`)}>Enter</button>
                      </div>
                    </>
                  )}
                </div>
                <h6 className='text-center text-capitalize text-secondary'>{item?.chatbot_name}</h6>
              </div>
            </div>
          ))}

          <div className='col-sm-12 col-md-12 col-lg-4 col-xl-3'>
            <div>
              <div className='adContainer'>
                {/* <h5>Create Chatbot</h5> */}
                <div className='chatadduser'>
                  <i onClick={handleAddNew} className="fa-solid fa-plus"></i>
                </div>
              </div>
              <h6 className='text-center text-capitalize text-secondary'>Create Chatbot</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chatbots;
