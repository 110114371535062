import '../../App.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import UserApiClient from '../../ApiClients';
import { BaseApiUrl } from '../../Configs/enviroment';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import DOMPurify from "dompurify";
import { UserContext } from '../../TabContextProvider';

function Chatbot({ data }) {

    const { chatbotData, chatbotAppearance } = data;
    const { Team } = useContext(UserContext);

    const [chatHistory, setChatHistory] = useState([]);
    const [chat, setChat] = useState([]);
    const [lastChatSource, setLastChatSource] = useState([]);
    const [inputText, setInputText] = useState('');
    const [isGenerating, setisGenerating] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const scrollbarRef = useRef();
    let isFetching = false;

    const toggleContent = () => {
        setIsVisible(!isVisible);
    };

    const handleDeleteClick = () => {
        Swal.fire({
            title: "Do you really want to clear the history?",
            showCancelButton: "No",
            confirmButtonText: "Yes ",
        }).then(async result => {
            if (result.isConfirmed) {
                await UserApiClient.delete(`bot/chat/clear-chat/`, {
                    chatbot_id: chatbotData.id,
                }, true).then(async (res) => {
                    const response = await res;
                    if (response.success) {
                        Swal.fire(response.data.data, "", "success", 3000);
                        setChat([]);
                        setLastChatSource([]);
                        setChatHistory([]);
                    }
                });
            }
        });
    };

    const textPatternFormatting = (text) => {
        let pattern = /^(###.*$)|\*\*(.*?)\*\*|\[(.*?)\]\((.*?)\)/gm;
        let result = text.replace(pattern, function (match, group1, group2, group3, group4) {
            if (group1) {
                return "<b>" + group1.replace(/^###\s*/, "") + "</b>";
            } else if (group2) {
                return "<b>" + group2 + "</b>";
            } else if (group3 && group4) {
                return '<a href="' + group4 + '" target="_blank" style="color: blue;">' + group3 + '</a>';
            }
        });
        return result;
    };

    async function fetchData() {
        isFetching = true;
        const res = await UserApiClient.get(`bot/chat/list/?chatbot_id=${chatbotData.id}`, {}, undefined, undefined, false);
        if (res.success) {
            setChatHistory(res.data)
            isFetching = false;
        }
    }

    const appendMessage = (text, sender) => {
        setChat((prevMessages) => [
            ...prevMessages,
            {
                text: text,
                sender: sender, // 'user' or 'bot'
            },
        ]);
    };

    const handleMessageSubmit = async () => {
        if (!inputText.trim()) return;

        setisGenerating(true);
        appendMessage(inputText, "user");

        let payload = {
            question: inputText,
            chatbot_id: chatbotData?.id,
            team_id: Team.id,
        };

        setInputText("");

        const response = await fetch(`${BaseApiUrl}bot/chat/response/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(payload),
        });
        setisGenerating(false);

        if (!response.ok || !response.body) {
            if (response.status == 426) {
                Swal.fire({
                    icon: 'warning',
                    title: "Limit exceed for messages, please upgrade your plan.",
                    showCancelButton: false,
                    timer: 3000
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: response.statusText,
                    showCancelButton: false,
                    timer: 3000
                })
            }

            if (chat.length > -1) {
                setChat(prevList => prevList.slice(0, prevList.length - 1));
            }
            return;
        }

        appendMessage("", "bot");
        processStream(response);
    };

    const processStream = async (response) => {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let fullResponse = "";

        while (true) {
            const { value, done } = await reader.read();
            if (done) {
                break;
            }
            const decodedChunk = decoder.decode(value, { stream: true });
            fullResponse += decodedChunk;
            const formattedText = textPatternFormatting(fullResponse); // Define this function as needed

            setChat((prev) => {
                const lastIndex = prev
                    .map((itm, index) => ({ itm, index }))
                    .filter(({ itm }) => itm.sender === "bot")
                    .pop()?.index;
                if (lastIndex !== undefined && lastIndex >= 0) {
                    return prev.map((itm, index) => {
                        if (index === lastIndex) {
                            return { ...itm, text: formattedText };
                        }
                        return itm;
                    });
                }
                return prev;
            });
        }
    };


    useEffect(() => {
        if (isVisible && chatbotData.id) {
            UserApiClient.get(`bot/chat/last-source/?chatbot_id=${chatbotData.id}`,).then(async (res) => {
                if (res.success) {
                    setLastChatSource(res.data.data);
                }
            });
        }
    }, [isVisible, chatbotData]);

    useEffect(() => {
        setTimeout(() => {
            if (scrollbarRef.current) {
                scrollbarRef.current.scrollTop = scrollbarRef.current.scrollHeight;
            }
        }, 100); // Delay might be needed to wait for the DOM to update
    }, [chat, chatHistory])

    useEffect(() => {
        if (chatbotData?.id && !isFetching) {
            fetchData()
        }
    }, [chatbotData])

    return (
        <>
            <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-10 col-xl-8'>
                    <div className='container p-0'>
                        <div className='chat-container'>
                            <div className='d-flex align-items-center p-2'><img src="/assets/Logo2.png" className="logo" style={{width:"35px"}} alt="logo" /><h5 className='ms-2 m-0'>Nimibot</h5> 
                            <div className='mx-2 px-2'>
                                <span className='chaticon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="12" height="12" style={{marginRight:"3px"}}>
                                        <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z" fill="#4ea4e2"/>
                                    </svg>
                                    AI</span></div></div>
                            {chat.length > 0 || chatHistory.length > 0 ? (
                                <button type="button" className="icn-btn" onClick={handleDeleteClick}>
                                    <i className="fa-solid fa-trash-can mx-4"></i>
                                </button>
                            ) : ''}
                        </div>
                        <div>
                            {chat.length == 0 && chatHistory.length == 0 ? (
                                <div style={{ textAlign: "center", background: "#fff", paddingTop: "60px" }}>
                                    <img src="/assets/Logo2.png" className="logo my-3" alt="logo" style={{ width: '7%' }} />
                                    <h4><strong>Nimibot AI Support</strong></h4>
                                    <span>{chatbotAppearance?.initial_message || 'Hi! What can I help with you ?'}</span>
                                </div>
                            ) : ""}
                        </div>

                        <div className='msgbboxcont' ref={scrollbarRef}>
                            {chatHistory && chatHistory.map((item, index) => (
                                <div key={index}>
                                    <div className={`usertext ${chatbotData.language == 'he' ? 'justify-content-start' : 'justify-content-end'}`}>
                                        <div className={`aigenerate ${chatbotData.language == 'he' ? 'text-end' : ''}`} >
                                            {item.question}
                                        </div>
                                    </div>
                                    <div className={`aitext ${chatbotData.language == 'he' ? 'justify-content-end' : 'justify-content-start'}`}>
                                        <pre className={`aigenerate ${chatbotData.language == 'he' ? 'text-end' : ''}`} dir={chatbotData.language == 'he' ? 'rtl' : 'ltr'}>
                                            {parse(`${textPatternFormatting(item.answer)}`)}
                                        </pre>
                                    </div>
                                </div>
                            ))}
                            {chat && chat.map((item, index) => (
                                <div key={index}>
                                    {item.sender == 'user' ? (
                                        <div className={`usertext ${chatbotData.language == 'he' ? 'justify-content-start' : 'justify-content-end'}`}>
                                            <div className={`aigenerate ${chatbotData.language == 'he' ? 'text-end' : ''}`}>
                                                {item.text}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={`aitext ${chatbotData.language == 'he' ? 'justify-content-end' : 'justify-content-start'}`}>
                                            <pre className={`aigenerate ${chatbotData.language == 'he' ? 'text-end' : ''}`} dir={chatbotData.language == 'he' ? 'rtl' : 'ltr'}>
                                                {parse(`${DOMPurify.sanitize(item.text)}`)}
                                            </pre>
                                        </div>
                                    )}
                                </div>
                            ))}
                            <div className={`pb-2 ${isGenerating ? "" : "d-none"}`}>
                                <div className={`aitext ${chatbotData.language == 'he' ? 'justify-content-end' : 'justify-content-start'}`}>
                                    <pre className={`aigenerate ${chatbotData.language == 'he' ? 'text-end' : ''}`} >
                                        <div className='ai_thinking'></div>
                                    </pre>
                                </div>
                            </div>
                        </div>

                        <div className='msgbox'>
                            <input type="text" className="form-control ms-4" value={inputText} onChange={(event) => { setInputText(event.target.value) }} onKeyUp={(event) => { if (event.key === "Enter") { handleMessageSubmit() } }} dir={chatbotData.language == 'he' ? 'rtl' : 'ltr'} autoFocus />
                            <button type="button" className="btn btn-primary mx-3" onClick={handleMessageSubmit}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.74928 11.2501L17.4993 2.50014M8.85559 11.5235L11.0457 17.1552C11.2386 17.6513 11.3351 17.8994 11.4741 17.9718C11.5946 18.0346 11.7381 18.0347 11.8587 17.972C11.9978 17.8998 12.0946 17.6518 12.2881 17.1559L17.78 3.08281C17.9547 2.63516 18.0421 2.41133 17.9943 2.26831C17.9528 2.1441 17.8553 2.04663 17.7311 2.00514C17.5881 1.95736 17.3643 2.0447 16.9166 2.21939L2.84349 7.71134C2.34759 7.90486 2.09965 8.00163 2.02739 8.14071C1.96475 8.26129 1.96483 8.40483 2.02761 8.52533C2.10004 8.66433 2.3481 8.7608 2.84422 8.95373L8.47589 11.1438C8.5766 11.183 8.62695 11.2026 8.66935 11.2328C8.70693 11.2596 8.7398 11.2925 8.7666 11.3301C8.79685 11.3725 8.81643 11.4228 8.85559 11.5235Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-sm- col-md- col-lg-2 col-xl-4 p-0'>
                    <div className='outer-space'>
                        <div className={`${isVisible ? 'show-btn' : ''}`}>
                            <button type="button" className="btn btn-outline-primary" onClick={toggleContent}>{isVisible ? 'Hide Sources' : 'Show Sources'}</button>
                            <div className={`${isVisible ? 'Show-Sources' : 'Hide-Sources'} p-3 overflow-auto`} style={{ maxHeight: "525px", scrollbarWidth: 'thin' }}>
                                {lastChatSource.map((item, index) => (
                                    <div className='mb-2'>
                                        {index != 0 ? (<hr />) : ""}
                                        <h5>{item?.source}</h5>
                                        <span>{item?.page_context}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Chatbot;
