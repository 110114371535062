const BaseApiUrl = process.env.REACT_APP_baseApiUrl;
const AppDomain = process.env.REACT_APP_appDomain;

const GoogleClientId = process.env.REACT_APP_googleClientId;
const GoogleRedirectUrl = process.env.REACT_APP_googleRedirectUrl;

const AwsAccessKey = process.env.REACT_APP_awsAccessKey
const AwsSecretKey = process.env.REACT_APP_awsSecretKey
const AwsRegion = process.env.REACT_APP_awsRegion
const AwsBucketName = process.env.REACT_APP_awsBucketName

export { BaseApiUrl, AppDomain, GoogleClientId, GoogleRedirectUrl, AwsAccessKey, AwsSecretKey, AwsRegion, AwsBucketName };