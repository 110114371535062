import '../App.css';
import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../Sidebars/Navbar.jsx';
import Header from '../Sidebars/header.jsx';
import Home from './Home'
import { UserContext } from '../TabContextProvider.jsx';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import UserApiClient from '../ApiClients/index.jsx';
import Chatbots from './Chatbots.jsx';
import Swal from 'sweetalert2';
import MainSetting from './MainSettings.jsx';
import Help from './Help.jsx';

function Layout() {

  const { chatbot_id, team_url } = useParams();

  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const { User, setUser, Team } = useContext(UserContext);
  const [chatbotData, setChatbotData] = useState({});
  const [chatbotAppearance, setChatbotAppearance] = useState({});
  const [chatbotList, setChatbotList] = useState([]);
  const [isFetchingList, SetIsFetchingList] = useState(false);
  let isFetching = false;

  // Your API call to fetch data
  const fetchData = async () => {
    isFetching = true
    try {
      const res = await UserApiClient.post(`bot/chatbot/${chatbot_id}/`, { user_id: User.id }, true);
      if (res.success) {
        setChatbotData(res.data.chatbot);
        setChatbotAppearance(res.data.chatbot_appearance);
      }
      isFetching = false
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  // Your API call to fetch data
  const fetchDataChatbotList = async () => {
    SetIsFetchingList(true);
    try {
      const res = await UserApiClient.get(`bot/chatbot/list/?team_id=${Team.id}`, {}, false, undefined, false);
      if (res.success) {
        setChatbotList(res.data);
      }
      SetIsFetchingList(false);
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const handleChatbotDelete = async (e, id) => {
    await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        await UserApiClient.delete(`bot/chatbot/delete/${id}/`, {}, true).then(async res => {
          if (res.success) {
            await fetchDataChatbotList();
            Swal.fire(res.data.message, '', 'success', 3000)
          }
        })
      }
    });
  }

  const handleAddNew = () => {
    Swal.fire({
      title: "Add New Chatbot",
      html: `
        <input id="name" type="text" class="form-control mx-auto mb-2 w-100" placeholder="Enter Chatbot Name" maxlength="100">
      `,
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Add New",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const name = Swal.getPopup().querySelector('#name').value;
        // Validate the fields
        if (!name) {
          Swal.showValidationMessage(`Name fields is required`);
          return;
        }

        // Create FormData object to handle file uploads
        const formData = new FormData();
        formData.append('chatbot_name', name);
        formData.append('team', Team.id);
        formData.append('created_by', User.id);

        const res = await UserApiClient.post(`bot/chatbot/add/${Team.created_by}/`, formData, true, undefined, false);
        if (res.success) {
          await fetchDataChatbotList();
          const data = await res.data;
          return { data };
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed && result.value.data) {
        Swal.fire({
          title: result.value.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2000
        });
      }
    });
  };

  useEffect(() => {
    if (team_url && Team?.slug != team_url) {
      navigate(`/dashboard/${Team?.slug}`)
    }
    if (window.location.href.includes('dashboard') && !team_url && Team.slug) {
      navigate(`/dashboard/${Team?.slug}`)
    }
  }, [Team])

  useEffect(() => {
    if (User.id &&
      !isFetchingList &&
      (window.location.href.includes('dashboard') || window.location.href.includes('settings') || window.location.href.includes('help')) &&
      Team.id) {
      fetchDataChatbotList();
    }
  }, [User, window.location.href, Team])

  useEffect(() => {
    if (User.id && !!token && !isFetching && chatbot_id) {
      fetchData();
    } else if (!chatbot_id) {
      setChatbotData({});
      setChatbotAppearance({});
    }
  }, [User, chatbot_id]);

  // useEffect(() => {
  //   if (!User.id || !token) {
  //     navigate('/login')
  //   }
  // }, [window.location.pathname])

  const data = { chatbotData, chatbotAppearance, User, Team, setChatbotData, setChatbotAppearance, setUser };
  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-sm-0 col-md-3 col-lg-2 col-xl-2 px-0 nav-mob" style={{ backgroundColor: "#fff", height: "100vh", position: "fixed"}}>
          <div>
          <Navbar chatbotList={chatbotList} />
          </div>
        </div>
        <div className="col-sm-12 col-md-9 col-lg-10 col-xl-10 for-mob">
          <Header />
          {window.location.pathname.includes('layout')
            ? (<Home data={data} />)
            : window.location.pathname.includes('dashboard')
              ? (<Chatbots chatbotList={chatbotList} handleChatbotDelete={handleChatbotDelete} handleAddNew={handleAddNew} isFetchingList={isFetchingList} />)
              : window.location.pathname.includes('settings')
                ? (<MainSetting chatbotList={chatbotList} />)
                : window.location.pathname.includes('help')
                  ? (<Help chatbotList={chatbotList} />)
                  : ''}
        </div>
      </div>
    </div>
  );
}

export default Layout;
